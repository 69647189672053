<template>
  <header class="tc-header" :style="{ background: $vuetify.theme.themes.light.primary }">
    <img :src="`/LOGO-${company.name}.png`" alt="Logomarca da Empresa">
  </header>
</template>

<script>
  export default {
    name: 'ApplicationHeader',
    props: {
      company: Object
    },
    created () {
      this.$vuetify.theme.themes.light.primary = this.$props.company.color
    }
  }
</script>

<style lang="scss" scoped>
@import "./../css/app.scss";

.tc-header{
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1%;

  img{
    max-width: 80%;
    padding: 1%;
  }
  h1#title {
    font-family: 'Gotham-Bold';
    color: white;
    font-size: 2rem;
    text-align: center;
    margin: 5vh 0 2vh 0;
    @media screen and (max-width: 700px) {
      font-size: 1.5rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 1.2rem;
      width: 80%;
    }
  }
}

</style>