<template>
  <div class="tc-container">
    <application-header :company="companies[company]"/>
    <v-alert
      type="success"
      v-if="alert.visible"
    >
      Seu currículo foi enviado.
    </v-alert>
    <form-trabalhe-conosco :company="companies[company]" :departments="departments" @form-data="sendData" :reset="reset"/>
  </div>
</template>

<script>
import FormTrabalheConosco from './../components/Form.vue';
import ApplicationHeader from './../components/Header.vue';
import axios from 'axios';

const components = {
  FormTrabalheConosco,
  ApplicationHeader
}

export default {
  name: 'TrabalheConosco',
  components,
  data: () => ({
    alert: {
      visible: false,
      message: ''
    },
    loading: false,
    reset:  false,
    company: 'Vetorial',
    departments: [],
    companies: {
      'Vetorial': {
        name: 'Vetorial',
        color: '#c21732',
      },
      'RazaoInfo': {
        name: 'RazaoInfo',
        color: '#ffc800',
      },
      'Bitcom': {
        name: 'Bitcom',
        color: '#ce0b14',
      }
    },
  }),
  methods: {
    setDepartments () {
      const params = new FormData()
      params.append('funcao', 'listDepartamento')
      axios.post('https://canicontrol.vetorial.net/nova/api/externo/rh.php', params).then(({data}) => {
        data.map((department) => {
          this.departments.push({
            text: department.depart_nome,
            value: department.departid
          })
        })
      });
    },
    sendData (target) {
      const params = new FormData()
      for (let key in target) params.append(key, target[key])
      axios.post('https://canicontrol.vetorial.net/nova/api/externo/rh.php', params).then(({data}) => {
        data.status === 'success' && (this.alert.visible = true)
        this.reset = data.status === 'success' ?  true : false
        setTimeout(() => {
          this.alert.visible = false
          this.reset = false
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        }, 3000);
      });
    }
  },
  created () {
    const url = window.location.href
    this.company = url.includes('vetorial') ? 'Vetorial' : (url.includes('bitcom') ? 'Bitcom' : 'RazaoInfo')
  },
  mounted () {
    document.title = `Trabalhe Conosco - ${this.company}`;
  },
  async beforeMount () {
    this.setDepartments()
  }
}
</script>

<style lang="scss" scoped>
@import "./../css/app.scss";
.tc-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  .v-alert {
    position: fixed;
    margin: 10px 5% 0 5%;
  }
}
</style>
