<template>
  <v-container
  class="tc-form-content"
  elevation="2"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
        <v-row>
          <h2>Faça parte da nossa equipe!</h2>
        </v-row>
        <v-row>
          <v-text-field
            label="Nome Completo"
            outlined
            v-model="name"
            :rules="[required]"
          ></v-text-field>
        </v-row>

        <v-row>
          <div class="col-left">
            <v-text-field
              v-model="cpf"
              label="CPF"
              outlined
              v-mask="'###.###.###-##'"
              :rules="[required]"
            ></v-text-field>
          </div>
          <div class="col-right">
              <v-text-field
                v-model="date"
                label="Data de Nascimento"
                outlined
                v-mask="'##/##/####'"
                :rules="[required]"
              ></v-text-field>
          </div>
        </v-row>
        <v-row>
          <div class="col-left">
            <v-text-field
              label="Número de telefone"
              outlined
              :type="'tel'"
              v-mask="['(##) ####-####', '(##) #####-####']"
              :rules="[required, telephone]"
              v-model="phoneNumber"
            ></v-text-field>
          </div>
          <div class="col-right">
            <v-text-field
              label="E-mail"
              outlined
              :rules="[required, email]"
              v-model="userEmail"
            ></v-text-field>
          </div>
        </v-row>
        <v-row class="tc-cols">
          <div class="col-left">
            <v-select
              :items="ufOptions"
              label="UF"
              outlined
              :rules="[required]"
              v-model="address.uf"
              @change="handleCities"
            ></v-select>
          </div>
          <div class="col-right">
            <v-select
              :items="citiesOptions"
              label="Cidade"
              outlined
              :rules="[required]"
              v-model="address.city"
              :disabled="citiesOptions ? false : true"
            ></v-select>
          </div>
        </v-row>
        <v-row>
          <div class="col-left">
            <v-select
              :items="confirmOptions"
              label="Você possui CNH?"
              outlined
              :rules="[required]"
              v-model="cnh.value"
            ></v-select>
          </div>
          <div class="col-right">
            <v-select
              :items="cnhOptions"
              multiple
              label="Se sim, qual?"
              outlined
              v-model="cnh.description"
              :disabled="cnh.value==='sim' ? false : true"
              @change="handleCNH"
            ></v-select>
          </div>
        </v-row>
        <!-- <v-row>
          <div class="col-left">
            <v-select
              :items="confirmOptions"
              label="Você é uma pessoa com deficiência?"
              outlined
              :rules="[required]"
              v-model="disability.value"
            ></v-select>
          </div>
          <div class="col-right">
            <v-text-field
              label="Se sim, qual a sua deficiência?"
              outlined
              :disabled="disability.value==='sim' ? false : true"
              v-model="disability.description"
            ></v-text-field>
          </div>
        </v-row> -->
        <v-row>
          <v-textarea
            outlined
            name="profile"
            label="Fale um pouco sobre seu perfil profissional"
            :rules="[required]"
            v-model="profile"
          ></v-textarea>
        </v-row>
        <v-row>
          <h5>Clique no campo abaixo e selecione o arquivo do seu currículo:</h5>
        </v-row>
        <v-row>
          <v-file-input
            show-size
            label="Currículo"
            outlined
            append-icon="mdi-file"
            prepend-icon=""
            :rules="[fileRequired, file]"
            v-model="resume"
            hint="Até 10MB"
            type="file"
            ref="file"
            accept="image/*, .pdf, .doc, .docx, .odt"
          ></v-file-input>
        </v-row>
        <v-row>
          <h5>Selecione o departamento ao qual deseja se candidatar </h5>
        </v-row>
        <v-row>
            <v-select
              :items="departments"
              label="Departamento"
              outlined
              :rules="[(v) => !!v || 'Escolha um departamento']"
              @change="handleDepartment"
              v-model="department"
            ></v-select>
        </v-row>
        <v-row  justify="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                target="_blank"
                :href="link"
                @click.stop
                v-bind="attrs"
                v-on="on"
                class="grey--text darken-1"
              >
                Aviso de Privacidade
              </a>
            </template>
            <span>Abrir PDF em nova aba</span>
          </v-tooltip>
        </v-row>
        <v-row justify="center">
          <v-btn large @click="validate" color="primary">Cadastrar Currículo</v-btn>
        </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapGetters } from 'vuex'
import axios from 'axios'

const props = {
  company: {
    type: Object
  },
  cities: {
    type: Array
  },
  departments: {
    type: Array
  },
  reset: {
    type: Boolean
  }
};

const computed = {
  ...mapGetters(['required', 'email', 'telephone', 'fileRequired']),
  age () {
    const rule = v => this.getAge(v) > 16 || 'A idade mínima é 16 anos'
    return rule
  },
  file () {
    const rule = v => v && v.size < 10000000 || 'O arquivo deve ter até 10MB'
    return rule
  }
}

const data = () => ({
  checkbox: false,
  link: '',
  fileModel: '',
  valid: true,
  items: [],
  date: '',
  name: '',
  cpf: '',
  phoneNumber: '',
  userEmail: '',
  department: '',
  address: {
    uf: '',
    city: ''
  },
  cnh: {
    value: '',
    description: ''
  },
  disability: {
    value: '',
    description: ''
  },
  profile: '',
  resume: null,
  departmentsOptions: [],
  occupation: null,
  cnhOptions: [
    {text: 'A', value: 'a', disabled: false},
    {text: 'B', value: 'b', disabled: false},
    {text: 'C', value: 'c', disabled: false},
    {text: 'D', value: 'd', disabled: false},
    {text: 'E', value: 'e', disabled: false},
  ],
  confirmOptions: [
    {text: 'Sim', value: 'sim'},
    {text: 'Não', value: 'nao'},
  ],
  ufOptions: ['RS', 'SC'],
  citiesOptions: null,
})

const methods = {
  handleCNH (target) {
    this.cnhOptions[1].disabled = false
    this.cnhOptions[2].disabled = false
    this.cnhOptions[3].disabled = false
    this.cnhOptions[4].disabled = false
    if (target.includes('b')) {
      this.cnhOptions[2].disabled = true
      this.cnhOptions[3].disabled = true
      this.cnhOptions[4].disabled = true
    } else if (target.includes('c')) {
      this.cnhOptions[1].disabled = true
      this.cnhOptions[3].disabled = true
      this.cnhOptions[4].disabled = true
    } else if (target.includes('d')) {
        this.cnhOptions[1].disabled = true
        this.cnhOptions[2].disabled = true
        this.cnhOptions[4].disabled = true
    } else if (target.includes('e')) {
        this.cnhOptions[1].disabled = true
        this.cnhOptions[2].disabled = true
        this.cnhOptions[3].disabled = true
    }
  },
  handleDepartment (target) {
    const params = new URLSearchParams();
    params.append('funcao', 'listCargo');
    params.append('departamento', target);
    axios.post('https://canicontrol.vetorial.net/nova/api/externo/rh.php', params).then(({data}) => {
      data.map((item) => {
        let name = item.cargo_nome.toLowerCase()
        if ((name.includes('novos currículos')) || (name.includes('novos curriculos'))) {
          this.occupation = {
            name: item.cargo_nome,
            id: item.cargoid
          }
        }
      })
      !this.occupation && console.log("Erro ao carregar cargo default.")
    });
  },
  validate () {
    const validate = this.$refs.form.validate()
    validate && this.$emit('form-data', {
      funcao: 'insert',
      nome: this.name,
      cpf: this.cpf,
      nasc: this.date,
      contato: this.phoneNumber,
      email: this.userEmail,
      cidade: this.address.city,
      deftrue: this.disability.description,
      cnh: this.cnh.description ? this.cnh.description : [this.cnh.value],
      perfil: this.profile,
      cargo: this.occupation.id,
      curriculo: this.resume,
      info: '',
      regiao: [4],
      cargo1: '',
      cargo2: '',
      foto: '',
    })
  },
  resetForm () {
    this.$refs.form.reset()
  },
  resetValidation () {
    this.$refs.form.resetValidation()
  },
  handleCities (uf) {
    const id = (uf === 'RS') ? '43' : '42'
    axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`).then(({data}) => {
      this.citiesOptions = []
      data.map((city) => {
        this.citiesOptions.push(city.nome)
      })
    })
  },
  getAge (value) {
    if (value.length === 10) {
      let date = new Date,
      birth = value.split('/'),
      year = birth[2],
      month = birth[1],
      day = birth[0],
      currentYear = date.getFullYear(),
      currentMonth = date.getMonth() + 1,
      currentDay = date.getDate(),
      age = currentYear - year;

      (currentMonth < month || currentMonth == month && currentDay < day) && age--

      return age < 0 ? 0 : age
    }
    return 0;
  }
}

export default { name: "FormTrabalheConosco", directives: { mask }, data, computed, methods, props,
  beforeMount () {
    this.$data.departmentsOptions = this.$props.departments
  },
  watch: {
    reset: function (value) {
      value && this.resetForm()
    }
  },
  created () {
    this.$vuetify.theme.themes.light.primary = this.$props.company.color
    const url = window.location.href.replace(/\/$/, "")
    this.link = `${url}/attachments/AVISO_DE_PRIVACIDADE_${this.$props.company.name.toUpperCase()}.pdf`
  }
};
</script>

<style lang="scss" scoped>
@import "./../css/app.scss";

.v-input__slot {
  align-items: center !important;
  justify-content: center !important;
}

h3 {
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 2rem;
  padding-bottom: 5%;
}

.tc-form-content {
  width: 60%;
  border-radius: 15px;
  max-height: 90vh;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    button {
      width: 225px;
      margin: 0 auto;
      margin-bottom: 15%;
    }
  }
  @media screen and (max-width: 700px) {
    width: 80%;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    button {
      width: 100%;
    }
    .v-btn__content {
      max-width: 90%;
    }
  }
  h5, h2 {
    font-size: 16px;
    font-weight: normal;
    width: 100% !important;
    text-align: center;
    margin-bottom: 2%;
  }
  h2 {
    font-size: 22px;
  }
  .row {
    a {
      text-align: center;
      margin-bottom: 15px;
    }
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      .col-left {
        width: 100%;
        .v-input {
          width: 100%;
        }
      }
      .col-right {
        width: 100%;
        .v-input {
          width: 100%;
        }
      }
    }
  }
  .col-left {
    width: 50%;
    .v-input {
      width: 95%;
    }
  }
  .col-right {
    width: 50%;
    .v-input {
      width: 95%;
      float: right;
    }
  }
}

form {
  min-width: 80%;
  padding: 2rem;
  .row {
    padding-bottom: 2%;
  }
  .tc-button {
    margin-bottom: 5%;
  }
}

</style>
