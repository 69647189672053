import Vue from 'vue'
import VueRouter from 'vue-router'
import TrabalheConosco from '../layouts/TrabalheConosco.vue'
Vue.use( VueRouter )

const routes = [
  {
    path: '/',
    name: 'home',
    component: TrabalheConosco,
  },
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});